import React, { useMemo } from 'react';
import type { FC } from 'react';

import { Inline, xcss } from '@atlaskit/primitives';

import { CommentNavigation as CommentNavigationCommon } from '@confluence/inline-comments-common';
import type { Comment } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { fg } from '@confluence/feature-gating';

import { useInlineCommentNavigation } from './hooks/useInlineCommentNavigation';

type CommentNavigationProps = {
	pageId: string;
	commentData: Comment;
	isEditor?: boolean;
	onNavigationClick: (nextMarkerRef: string) => void;
	disableCommentCount: boolean;
};

const positionRightStyles = xcss({
	position: 'absolute',
	top: 'space.150',
	right: 'space.800',
});

const positionLeftStyles = xcss({
	position: 'absolute',
	top: 'space.150',
	left: 'space.150',
});

export const CommentNavigation: FC<CommentNavigationProps> = ({
	pageId,
	commentData,
	isEditor,
	onNavigationClick,
	disableCommentCount,
}) => {
	const { currentCommentIndex, goToNextInlineComment, goToPrevInlineComment, commentsCount } =
		useInlineCommentNavigation({
			isEditor,
			pageId,
			onNavigationClick,
			commentData,
		});

	return useMemo(
		() => (
			<Inline
				xcss={fg('confluence-frontend-comments-panel') ? positionRightStyles : positionLeftStyles}
			>
				<CommentNavigationCommon
					goToNextInlineComment={goToNextInlineComment}
					goToPrevInlineComment={goToPrevInlineComment}
					currentCommentIndex={currentCommentIndex}
					commentsCount={commentsCount}
					disableCommentCount={disableCommentCount}
				/>
			</Inline>
		),
		[
			commentsCount,
			currentCommentIndex,
			goToNextInlineComment,
			goToPrevInlineComment,
			disableCommentCount,
		],
	);
};
