import { useMemo } from 'react';

import {
	calculateEditorOffsetFromParent,
	calculateRendererOffsetFromParent,
} from '@confluence/comments-util/entry-points/domUtils';

type SidebarProps = {
	annotationElement: HTMLElement | null | undefined;
	isEditor: boolean;
	isViewCommentMode?: boolean;
	windowWidth: number;
};

export const useCommentSidebarOffset = ({
	annotationElement,
	isEditor,
	isViewCommentMode,
	windowWidth, // Re-calculate when this changes
}: SidebarProps) => {
	return useMemo(() => {
		if (!annotationElement) return 0;

		if (isEditor) {
			return calculateEditorOffsetFromParent(
				annotationElement,
				'.fabric-editor-popup-scroll-parent',
			);
		}

		return calculateRendererOffsetFromParent(
			annotationElement,
			`[data-annotation-draft-mark="true"]`,
			Boolean(isViewCommentMode),
		);
		// We want to re-calculate when the window width changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditor, annotationElement, isViewCommentMode, windowWidth]);
};
